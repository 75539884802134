/**
 * @return {string}
 */
const utils = {
  randomId: function () {
    return `_${Math.random().toString(36).substr(2, 9)}`
  },
  calculateAge: function (dateString) {
    let today = new Date()
    let birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },
  /**
   * Modifica el string de error para hacerlo más legible.
   *
   * @param {String} msg Cadena de error
   */
  formatMsg(msg) {
    msg = msg.replace('non_field_errors: ', '')
    msg = msg.replace('[object Object]', '')
    // Mensajes pacientes
    msg = msg.replace(
      'waking_time: ',
      'Hora despertar: Hora con formato erróneo o vacío.'
    )
    msg = msg.replace(
      'time_to_sleep: ',
      'Hora dormir: Hora con formato erróneo o vacío.'
    )
    msg = msg.replace(
      'patient_objectives_add: oneMonth: ',
      'Objetivos -> Objetivo a 1 mes: '
    )
    msg = msg.replace(
      'patient_objectives_add: threeMonth: ',
      'Objetivos -> Objetivo a 3 meses: '
    )
    msg = msg.replace(
      'patient_objectives_add: sixMonth: ',
      'Objetivos -> Objetivo a 6 meses: '
    )
    // Mensajes citas
    msg = msg.replace('weight', 'Peso (kg)')
    msg = msg.replace('size', 'Talla (m)')
    msg = msg.replace('bmi', 'IMC')
    msg = msg.replace('grease', 'Grasa (%)')
    msg = msg.replace('muscle', 'Músculo (kg)')
    msg = msg.replace('waist', 'Cintura (cm)')
    msg = msg.replace('hip', 'Cadera (cm)')
    msg = msg.replace('arm', 'Brazo (cm)')
    msg = msg.replace('objectives_to_meet', 'Objetivos a cumplir')
    msg = msg.replace('traetment_plan', 'Plan de tratamiento')
    // Mensajes de cita general
    msg = msg.replace('reason', 'Motivo de Consulta')
    msg = msg.replace('suffering', 'Padecimiento Actual')
    msg = msg.replace('pressure', 'Presión Arterial (mmHG)')
    msg = msg.replace('cardiac_frequency', 'Frecuencia Cardiaca (Ipm)')
    msg = msg.replace('oxygen', 'Saturación de Oxígeno(%)')
    msg = msg.replace('temperature', 'Temperatura(°C)')
    msg = msg.replace('breathing_frequency', 'Frecuencia Respiratoria (rpm)')
    msg = msg.replace('imc', 'IMC')
    msg = msg.replace('diagnostic_impression', 'Impresión Diagnósticarios')

    return msg
  },
  getErrorDetails: function (errors) {
    var toType = function (obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    }

    let errorDetails = ''
    let messages = ''
    errors.some((err) => {
      if (toType(err['message']) === 'object') {
        let messagesObj = err['message']
        Object.keys(messagesObj).some((messageInput) => {
          let formatMessage = messagesObj[messageInput].join(', ')
          messages += `${messageInput}: ${formatMessage} \n`
          return true
        })
      } else {
        messages = err['message']
      }
      errorDetails += `${err['field']}: ${messages} \n`
      return true
    })
    return utils.formatMsg(errorDetails)
  },
  getErrorMessageWithoutField: function (errors) {
    var toType = function (obj) {
      return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase()
    }

    let errorDetails = ''
    let messages = ''
    errors.some((err) => {
      if (toType(err['message']) === 'object') {
        let messagesObj = err['message']
        Object.keys(messagesObj).some((messageInput) => {
          let formatMessage = messagesObj[messageInput].join(', ')
          messages += `${messageInput}: ${formatMessage} \n`
          return true
        })
      } else {
        messages = err['message']
      }
      errorDetails += `${messages} \n`
      return true
    })
    return utils.formatMsg(errorDetails)
  },
  getFileNameFromUrl: function (url) {
    return url.substring(url.lastIndexOf('/') + 1)
  },
  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  notBeforeToday: function (date) {
    return date < new Date(new Date().setHours(0, 0, 0, 0) + 1)
  },
  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  notAfterToday: function (date) {
    return date > new Date(new Date().setHours(0, 0, 0, 0))
  },
  /**
   * Método para evitar que el datepicker muestre fechas anteriores al día actual
   */
  notAfterYear: function (date) {
    var today = new Date()
    var year = new Date(today).getFullYear()
    var month = new Date(today).getMonth()
    var day = new Date(today).getDate()
    return date > new Date(new Date().setFullYear(year - 1, month, day - 1))
  },
  getUrlVars: function () {
    let vars = {}
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
      m,
      key,
      value
    ) {
      vars[key] = value
    })
    return vars
  },
  /**
   * Da formato a una fecha y hora (Datetime) de python
   * @param {String} dt Datetime a formatear
   * @param {String} charInDt Carácter separador entre fecha y horas
   * @param {String} charReplace Carácter que remplazará
   */
  formatDateTime(dt, charInDt = 'T', charReplace = ' ') {
    return dt.replace(charInDt, charReplace)
  },
  /**
   * Objeto con la lista de roles disponibles.
   *
   */
  roles: {
    ADMIN: 'admin',
    PATIENT: 'patient',
    DOCTOR: 'doctor',
    ASSISTANT: 'assistant',
  },
  /**
   * Objeto con la lista de tipo de consultorio disponibles.
   *
   */
  consultingRoomTypes: {
    NUTRICION: 1,
    GINECO: 2,
    GENERAL: 3,
  },
}
module.exports = utils
