import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import consultingroom from '@/modules/consultingroom/store'
import catalogs from '@/modules/catalogs/store'
import patients from '@/modules/patients/store'
import users from '@/modules/users/store'
import appointments from '@/modules/appointments/store'
import marketing from '@/modules/marketing/store'
import appointmentsgeneral from '@/modules/appointmentsgeneral/store'
import patientsgeneral from '@/modules/patientsgeneral/store'
import prescriptions from '@/modules/prescriptions/store'
import documentsprinting from '@/modules/documentsprinting/store'

import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'patients', 'consultingroom', 'patientsgeneral'],
})
export default new Vuex.Store({
  state: {
    activeLoading: false,
    loadingPageMsg: '',
    showNavbar: true,
    errorMsg: '',
    skeletonLoading: true,
  },
  mutations: {
    setLoading(state, { isLoading, msg }) {
      state.activeLoading = isLoading
      state.loadingPageMsg = msg
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg
    },
    setSkeletonLoading: (state, isLoading) => {
      state.skeletonLoading = isLoading
    },
    setShowNavbar(state, showNavbar) {
      state.showNavbar = showNavbar
    },
  },
  modules: {
    auth,
    consultingroom,
    catalogs,
    patients,
    users,
    appointments,
    marketing,
    appointmentsgeneral,
    patientsgeneral,
    prescriptions,
    documentsprinting,
  },
  plugins: [vuexLocal.plugin],
})
