export default {
  tokenAccess: null,
  tokenRefresh: null,
  user: {
    id: null,
    username: '',
    name: '',
    lastName: '',
    secondLastName: '',
    email: '',
    phoneNumber: '',
    userprofile: {
      picture: '#',
      consultingRoom: {
        id: null,
        name: '',
      },
      role: '',
    },
  },
}
