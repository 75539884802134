const _ = require('lodash')
const { DateTime } = require('luxon')

export function finalNewPatientGeneral(store) {
  let finalPatient = _.cloneDeep(store.newPatientGeneral)
  finalPatient.dateOfBirth = DateTime.fromISO(
    finalPatient.dateOfBirth
  ).toFormat('yyyy-MM-dd')

  if (
    finalPatient.ginecoAdd.dateMenstruation !== null &&
    finalPatient.ginecoAdd.dateMenstruation !== ''
  ) {
    finalPatient.ginecoAdd.dateMenstruation = DateTime.fromISO(
      finalPatient.ginecoAdd.dateMenstruation
    ).toFormat('yyyy-MM-dd')
  } else {
    finalPatient.ginecoAdd.dateMenstruation = null
  }

  if (finalPatient.photoId === undefined) {
    finalPatient.photoId = null
  }

  if (finalPatient.patientDocsId === undefined) {
    finalPatient.patientDocsId = null
  }

  return finalPatient
}

export function finalUpdatePatient(store) {
  let finalPatient = _.cloneDeep(store.updatePatient)
  finalPatient.dateOfBirth = DateTime.fromISO(
    finalPatient.dateOfBirth
  ).toFormat('yyyy-MM-dd')

  if (
    finalPatient.ginecoAdd.dateMenstruation !== null &&
    finalPatient.ginecoAdd.dateMenstruation !== ''
  ) {
    finalPatient.ginecoAdd.dateMenstruation = DateTime.fromISO(
      finalPatient.ginecoAdd.dateMenstruation
    ).toFormat('yyyy-MM-dd')
  } else {
    finalPatient.ginecoAdd.dateMenstruation = null
  }
  if (finalPatient.photoId === undefined) {
    finalPatient.photoId = null
  }
  if (finalPatient.patientDocsId === undefined) {
    finalPatient.patientDocsId = null
  }
  return finalPatient
}

export function getNewPatientGeneralErrors(store) {
  let finalNewPatientGeneralErrors = {
    generalInformation: {
      patientData: [],
      contact: [],
    },
  }
  // Datos del paciente
  if (store.newPatientGeneralErrors.hasOwnProperty('name')) {
    finalNewPatientGeneralErrors.generalInformation.patientData.push({
      name: 'Nombre',
      msg: store.newPatientGeneralErrors.name,
    })
  }
  if (store.newPatientGeneralErrors.hasOwnProperty('lastName')) {
    finalNewPatientGeneralErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.newPatientGeneralErrors.lastName,
    })
  }
  if (store.newPatientGeneralErrors.hasOwnProperty('secondLastName')) {
    finalNewPatientGeneralErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.newPatientGeneralErrors.secondLastName,
    })
  }
  if (store.newPatientGeneralErrors.hasOwnProperty('dateOfBirth')) {
    finalNewPatientGeneralErrors.generalInformation.patientData.push({
      name: 'Fecha de nacimiento',
      msg: store.newPatientGeneralErrors.dateOfBirth,
    })
  }
  // Contacto
  if (store.newPatientGeneralErrors.hasOwnProperty('emailsContactAdd')) {
    finalNewPatientGeneralErrors.generalInformation.contact.push({
      name: 'Email de contacto',
      msg: store.newPatientGeneralErrors.emailsContactAdd[0],
    })
  }
  if (store.newPatientGeneralErrors.hasOwnProperty('phonesContactAdd')) {
    finalNewPatientGeneralErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.newPatientGeneralErrors.phonesContactAdd[0],
    })
  }

  return finalNewPatientGeneralErrors
}

export function getUpdatePatientErrors(store) {
  let finalUpdatePatientErrors = {
    generalInformation: {
      patientData: [],
      contact: [],
      address: [],
    },
  }
  // Datos del paciente
  if (store.updatePatientErrors.hasOwnProperty('name')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Nombre',
      msg: store.updatePatientErrors.name,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('lastName')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.updatePatientErrors.lastName,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('secondLastName')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.updatePatientErrors.secondLastName,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('dateOfBirth')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Fechande nacimiento',
      msg: store.updatePatientErrors.dateOfBirth,
    })
  }

  return finalUpdatePatientErrors
}
