export let appointmentObject = {
  patient: '',
  weight: '',
  size: '',
  bmi: '',
  grease: '',
  muscle: '',
  musclePercentage: '',
  waist: '',
  hip: '',
  arm: '',
  leg: '',
  chest: '',
  contractedArm: '',
  calf: '',
  objectivesToMeet: '',
  treatmentPlan: '',
  feedbackReaction: '',
  nextAppointmentTime: '',
  previousAppointment: null,
  appointmentStatus: 'CO',
  nutritionalPlanAdd: [],
  pcse: '',
  pct: '',
  pcb: '',
  pcsi: '',
}
