const CaptureGeneralAppointmentView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointmentsgeneral/views/CaptureGeneralAppointmentView'
  )
const EditGeneralAppointmentView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointmentsgeneral/views/EditGeneralAppointmentView'
  )
const PatientGeneralAppointmentsView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointmentsgeneral/views/PatientGeneralAppointmentsView'
  )

export default [
  {
    path: '/capturar-cita-general/:id',
    name: 'captureAppointmentGeneral',
    meta: {
      title: 'Capturar cita general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: CaptureGeneralAppointmentView,
  },
  {
    path: '/editar-cita-general/:id',
    name: 'editAppointmentGeneral',
    meta: {
      title: 'Editar cita general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: EditGeneralAppointmentView,
  },
  {
    path: '/citas-del-paciente-general/:patientGeneralId/',
    name: 'patientAppointmentsGeneral',
    meta: {
      title: 'Citas del paciente general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: false,
    },
    component: PatientGeneralAppointmentsView,
  },
]
