import { newConsultingRoomObject } from '@/models/newConsultingRoom'
const _ = require('lodash')

export default {
  users: [],
  emailError: false,
  consultingRoom: {
    id: null,
    name: '',
    picture: '#',
    email: '',
    phone: '',
    address: '',
  },
  socialNetworks: {
    facebookUrl: '',
    instagramUrl: '',
    webUrl: '',
    twitterUrl: '',
    whatsappNumber: '',
  },
  newConsultingRoom: _.cloneDeep(newConsultingRoomObject),
  logoPicture: new FormData(),
  newConsultingRoomProgress: {
    generalData: false,
    invitationDoctor: false,
    reportImages: false,
    socialMedia: false,
  },
  infographicsList: [],
  totalInfographicsPages: 0,
}
