<template>
  <vs-sidebar
    v-model="active"
    parent="body"
    :default-index="getDefaultIndex()"
    color="primary"
    class="sidebarx"
    :click-not-close="true"
    spacer
  >
    <div slot="header" class="header-sidebar">
      <div class="header-desc">
        <vs-button
          icon="close"
          color="primary"
          type="flat"
          @click="active = false"
        />
      </div>
      <template v-if="user.userprofile.picture !== '#'">
        <vs-avatar size="70px" :src="user.userprofile.picture" />
      </template>
      <vs-avatar
        v-else
        size="70px"
        :src="require('@/assets/images/user-avatar.png')"
      />

      <h4 class="small-font">
        {{ user.name }} {{ user.lastName }} {{ user.secondLastName }}
      </h4>
      <span class="small-font">{{ user.email }}</span>
      <h4 class="mt-1">{{ consultingRoom.name }}</h4>
    </div>
    <!-- Mi Perfil -->
    <div @click="selectPage('Mi Perfil')">
      <router-link
        class="side-bar-router mb-1"
        :class="{
          'active-route': selectedPage === 'ReMi Perfilportes',
        }"
        :to="{
          name: 'MyProfile',
        }"
      >
        <span class="material-icons mr-1 font-s-1">
          account_box
        </span>
        Mi Perfil
      </router-link>
    </div>
    <vs-sidebar-group
      v-if="user.userprofile.role === roles.PATIENT"
      title="Progreso"
      :open="true"
    >
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.NUTRICION
        "
        @click="selectPage('Mis Citas')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Mis Citas',
          }"
          :to="{
            name: 'patientAppointments',
            params: { patientId: user.patient },
          }"
        >
          <span class="material-icons mr-1 font-s-1"> view_list </span>
          Mis Citas
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.GENERAL
        "
        @click="selectPage('Mis Citas')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Mis Citas',
          }"
          :to="{
            name: 'patientAppointmentsGeneral',
            params: { patientGeneralId: user.patientgeneral },
          }"
        >
          <span class="material-icons mr-1 font-s-1"> view_list </span>
          Mis Citas
        </router-link>
      </div>
      <!-- Avances del paciente -->
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.NUTRICION
        "
        @click="selectPage('Mis Avances')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Mis Avances',
          }"
          :to="{
            name: 'patientProgress',
            params: { id: user.patient },
          }"
        >
          <span class="material-icons mr-1 font-s-1"> show_chart </span>
          Mis Avances
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.GENERAL
        "
        @click="selectPage('Mis Avances')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Mis Avances',
          }"
          :to="{
            name: 'patientGeneralProgress',
            params: { id: user.patientgeneral },
          }"
        >
          <span class="material-icons mr-1 font-s-1"> show_chart </span>
          Mis Avances
        </router-link>
      </div>
    </vs-sidebar-group>
    <vs-divider
      v-if="
        !(
          user.userprofile.role === roles.ADMIN ||
          user.userprofile.role === roles.DOCTOR
        )
      "
      position="left"
    />
    <!-- Pacientes -->
    <vs-sidebar-group
      v-if="
        user.userprofile.role === roles.ASSISTANT ||
        user.userprofile.role === roles.ADMIN ||
        user.userprofile.role === roles.DOCTOR
      "
      title="Pacientes"
      :open="true"
    >
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.NUTRICION
        "
        @click="selectPage('Lista de Pacientes')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Lista de Pacientes',
          }"
          :to="{
            name: 'patientsList',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> account_box </span>
          Lista de Pacientes
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.GENERAL
        "
        @click="selectPage('Lista de Pacientes Generales')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Lista de Pacientes Generales',
          }"
          :to="{
            name: 'patientsGeneralList',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> account_box </span>
          Lista de Pacientes Generales
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.NUTRICION
        "
        @click="selectPage('Agregar Paciente')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Agregar Paciente',
          }"
          :to="{
            name: 'newPatient',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> person_add </span>
          Agregar Paciente
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.NUTRICION
        "
        @click="selectPage('Agregar Paciente Prospecto')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Agregar Paciente Prospecto',
          }"
          :to="{
            name: 'newProspectPatient',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> person_add </span>
          Agregar Paciente Prospecto
        </router-link>
      </div>
      <div
        v-if="
          user.userprofile.consultingRoom.consultingRoomType.id ===
          consultingRoomTypes.GENERAL
        "
        @click="selectPage('Agregar Paciente General')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Agregar Paciente General',
          }"
          :to="{
            name: 'newPatientGeneral',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> person_add </span>
          Agregar Paciente General
        </router-link>
      </div>
    </vs-sidebar-group>
    <vs-sidebar-group
      v-if="
        consultingRoom.consultingRoomType.allowPrescriptions !== undefined &&
        consultingRoom.consultingRoomType.allowPrescriptions &&
        (user.userprofile.role === roles.ASSISTANT ||
          user.userprofile.role === roles.ADMIN ||
          user.userprofile.role === roles.DOCTOR)
      "
      title="Prescripciones"
      :open="true"
    >
      <div @click="selectPage('Configuración de Prescripciones')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Configuración de Prescripciones',
          }"
          :to="{
            name: 'PrescriptionConfiguration',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> article </span>
          Configuración de Prescripciones
        </router-link>
      </div>
    </vs-sidebar-group>
    <!-- Mi Consultorio -->
    <vs-sidebar-group
      v-if="
        user.userprofile.role === roles.ADMIN ||
        user.userprofile.role === roles.DOCTOR
      "
      title="Mi Consultorio"
      :open="true"
    >
      <div @click="selectPage('Pacientes')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Pacientes',
          }"
          :to="{
            name: 'ConsultingRoomPatients',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> account_box </span>
          Pacientes
        </router-link>
      </div>
      <div @click="selectPage('DocumentosImpresion')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'DocumentosImpresion',
          }"
          :to="{
            name: 'DocumentsPrinting',
          }"
        >
          <span class="material-icons mr-1 font-s-1"> file_present </span>
          Documentos de impresión rápida
        </router-link>
      </div>
      <div @click="selectPage('Usuarios')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Usuarios',
          }"
          :to="{
            name: 'ConsultingRoomAssistants',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            supervised_user_circle
          </span>
          Usuarios
        </router-link>
      </div>
      <div
        v-if="consultingRoom.consultingRoomType.id != 3"
        @click="selectPage('Reportes')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Reportes',
          }"
          :to="{
            name: 'consultingRoomReports',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            style
          </span>
          Reportes
        </router-link>
      </div>
      <div @click="selectPage('Redes Sociales')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Redes Sociales',
          }"
          :to="{
            name: 'consultingRoomSocialNetworks',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            public
          </span>
          Redes Sociales
        </router-link>
      </div>
      <div @click="selectPage('Infografias')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Infografias',
          }"
          :to="{
            name: 'consultingRoomInfographics',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            notification_important
          </span>
          Infografias
        </router-link>
      </div>
      <div @click="selectPage('Catálogos')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Catálogos',
          }"
          :to="{
            name: 'catalogs',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            notification_important
          </span>
          Catálogos
        </router-link>
      </div>
      <div
        v-if="user.userprofile.role === roles.ADMIN"
        @click="selectPage('Nuevo Consultorio')"
      >
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Nuevo Consultorio',
          }"
          :to="{
            name: 'consultingRoom',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            fiber_new
          </span>
          Nuevo Consultorio
        </router-link>
      </div>
      <div @click="selectPage('Configuración General')">
        <router-link
          class="side-bar-router mb-1"
          :class="{
            'active-route': selectedPage === 'Configuración General',
          }"
          :to="{
            name: 'consultingRoomGeneralSettings',
          }"
        >
          <span class="material-icons mr-1 font-s-1">
            settings
          </span>
          Configuración General
        </router-link>
      </div>
    </vs-sidebar-group>
    <div slot="footer" class="footer-sidebar">
      <vs-button icon="reply" color="danger" type="flat" @click="_logout">
        Cerrar Sesión
      </vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { eventBus } from '@/plugins/event-bus'

import utils from '@/utils/utils'

export default {
  name: 'MainSideBar',
  data: function () {
    return {
      active: false,
      selectedPage: '',
      roles: utils.roles,
      consultingRoomTypes: utils.consultingRoomTypes,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('consultingroom', {
      consultingRoom: 'consultingRoom',
    }),
    ...mapGetters('auth', {
      isDoctorOrAdmin: 'isDoctorOrAdmin',
    }),
  },
  created: function () {
    eventBus.$on('showSideBar', this.showSideBar)
  },
  methods: {
    ...mapActions('auth', { logout: 'logout' }),
    showSideBar() {
      this.active = true
    },
    selectPage(pageName) {
      this.active = false
      this.selectedPage = pageName
    },
    getDefaultIndex() {
      if (this.isDoctorOrAdmin) {
        return '3.1'
      }
      return '1.3'
    },
    _logout() {
      this.logout()
      this.$router
        .push({
          name: 'authLogin',
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
.side-bar-router {
  padding: 10px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: inherit;
  opacity: 0.8;
  font-size: 0.85rem;
  text-decoration: none;
  &:hover {
    color: rgba(var(--vs-primary), 1) !important;
    opacity: 1 !important;
  }
}

.active-route {
  color: rgba(var(--vs-primary), 1) !important;
  opacity: 1 !important;
  font-weight: bold;
}

.long-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .header-desc {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
}
</style>
