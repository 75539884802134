export default {
  /**
   * Configura las citas del paciente que ya se concretaron.
   */
  setDocumentsPrintingList: (state, documentsPrintingList) => {
    state.documentsPrintingList = documentsPrintingList
  },
  /**
   * Configura el FormData para los documentos de la cita.
   */
  setNewDocumentPrinting: (state, formData) => {
    state.documentPrinting = formData
  },
}
