import axios from 'axios'

export async function updateUser({ commit }, { data, username }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/users/${username}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updateUserProfile({ commit }, { data, username }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando perfil del usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/users/${username}/profile/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Método de obtención de usuario.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getSubscription({ commit }, username) {
  return new Promise((resolve, reject) => {
    let url = `/users/${username}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data['user']['userprofile']['subscription'])
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateProfilePicture({ commit }, { formData, username }) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/users/${username}/profile/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Método de obtención de usuarios del sistema.
 *
 * @param params params Parámetros para configurar los filtros de búsqueda.
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getUsers({ commit }, params = null) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo usuarios',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/users/`
    url = params !== null ? `${url}?${params}` : url
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Método para obtener los datos de un usuario
 *
 * @param username String Username del usuario para obtener su información
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getUser({ commit }, username) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo información del usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/users/${username}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getDoctorGeneralProfile({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/general-doctor-profile/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export async function updateDoctorGeneralProfile({ commit }, { data, id }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/general-doctor-profile/${id}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
