import axios from 'axios'

/**
 * Retorna la lista de citas conforme a los filtros brindados.
 *
 * @param {String} params query params (Opcionales)
 */
// eslint-disable-next-line no-unused-vars
export async function getAppointmentsList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/appointments/'
    url = params !== null ? `${url}?${params}` : url

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite reagendar una nueva cita.
 *
 * @param {Object} data Objeto de la cita a agendar
 */
// eslint-disable-next-line no-unused-vars
export async function ModScheduleAppAction(context, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/modify-schedelued-appointment/${id}/`
    axios
      .put(url, data, {})
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Retorna una cita de acuerdo al id brindado

 * @param {number} id indetificador de la cita
 */
export async function getAppointmentById({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos de la cita',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/appointments/${id}/`

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Retorna una cita de acuerdo al id brindado

 * @param {number} id indetificador de la cita
 */
export async function getGrease({ commit }, data) {
  return new Promise((resolve, reject) => {
    let url = `/appointments/calculate-grease/`
    axios
      .post(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite crear una cita, ya sea agendada o sin agendar.
 *
 * @param {Object} data Objeto requerido para capturar una cita
 */
// eslint-disable-next-line no-unused-vars
export async function newAppointmentAction(context, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/appointments/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite agendar una nueva cita.
 *
 * @param {Object} data Objeto de la cita a agendar
 */
// eslint-disable-next-line no-unused-vars
export async function scheduleAppointmentAction(context, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/appointments/make-an-appointment/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Actualizar una cita dado su identificador y datos necesarios mediante un médoto PUT.
 *
 * @param {Object} obj.data Objeto de la cita a actualizar
 * @param {Object} obj.id   Identificador de la cita a actualizar
 */
// eslint-disable-next-line no-unused-vars
export async function updateAppointmentAction(context, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/appointments/${id}/`
    axios
      .put(url, data, {})
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite cargar documentos a una cita previamente creada.
 *
 * @param {Object} obj.formData FormData con los documentos de la cita.
 * @param {Object} obj.id       Identificador de la cita a actualizar
 */
// eslint-disable-next-line no-unused-vars
export async function saveAppointmentFormData(context, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/appointment-docs/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite eliminar una cita de la base de datos
 * @param {Number} id Identificar de la cita que se eliminará
 */
export async function deleteAppointmentAction(context, { data, id }) {
  return new Promise((resolve, reject) => {
    let url = `/cancel-appointment/${id}/`
    axios
      .put(url, data, {})
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Endpoint para generae el reporte de la cita y enviarlo por email al paciente.
 *
 * @param {Number} id Identificador de la cita
 */
export async function createAppointmentReportAction({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando reporte de la cita...',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/appointment-reports/`
    axios
      .post(url, { appointment: id })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Endpoint para actualizar el reporte de una cita.
 *
 * @param {Number} obj.appointmentReportId Identificador del reporte de la cita a actualizar
 * @param {Number} obj.appointmentId Identificador de la cita
 */
export async function updateAppointmentReportAction(
  { commit },
  { appointmentReportId, appointmentId }
) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando reporte de la cita...',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/appointment-reports/${appointmentReportId}/`
    axios
      .put(url, { appointment: appointmentId })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
