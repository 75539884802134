import { newConsultingRoomObject } from '@/models/newConsultingRoom'
const _ = require('lodash')

export default {
  /**
   * Actualiza los usuarios del consultorio
   */
  setUsers: (state, users) => {
    state.users = users
  },
  /**
   * Actualiza el consultorio actual
   */
  setConsultingRoom: (state, consultingRoom) => {
    state.consultingRoom = consultingRoom
  },
  /**
   * Actualiza las redes sociales del consultorio
   */
  setSocialNetworks: (state, socialNetworks) => {
    state.socialNetworks = socialNetworks
  },
  /**
   * Actualiza la lista de infografias
   */
  setInfographicsList: (state, infographicsList) => {
    state.infographicsList = infographicsList
  },
  /**
   * Paginas totales de infografias
   */
  setTotalInfographicsPages: (state, totalInfographicsPages) => {
    state.totalInfographicsPages = totalInfographicsPages
  },
  /**
   * Actualiza los datos de un nuevo consultorio
   */
  setNewConsultingRoomData: (state, newConsultingRoom) => {
    state.newConsultingRoom = _.merge(
      state.newConsultingRoom,
      newConsultingRoom
    )
  },
  /**
   * Reiniciar el objeto de nuevo consultorio
   */
  clearNewConsultingRoomData: (state) => {
    state.newConsultingRoom = _.cloneDeep(newConsultingRoomObject)
    state.newConsultingRoomProgress = {
      generalData: false,
      invitationDoctor: false,
      reportImages: false,
    }
  },
  /**
   * Configura el progreso para la vista de nuevos consultorios.
   */
  setNewConsultingRoomProgress: (state, { card, status }) => {
    state.newConsultingRoomProgress[card] = status
  },
  /**
   * Actualiza el logo del consultorio.
   */
  setLogoPicture: (state, formData) => {
    state.logoPicture = formData
  },
}
