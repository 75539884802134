import axios from 'axios'

/**
 * Envía la petición al servidor para el envío de la invitación de nuevo usuario.
 * @param data Object Objeto con la información del paciente
 * @returns {Promise<Object>}
 */
export async function inviteNewUserAction({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de invitación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation_from_new_consulting_room/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Envía la petición al servidor para el envío de la invitación de nuevo usuario.
 * @param data Object Objeto con la información del paciente
 * @returns {Promise<Object>}
 */
export async function inviteUserAction({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de invitación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation_consulting_room/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updateConsultingRoomPicture(
  // eslint-disable-next-line no-unused-vars
  { commit },
  { formData, consultingRoomId }
) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/consulting-room/${consultingRoomId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene los detalles del consultorio.
 *
 * @param consultingRoomId Identificador del consultorio.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getConsultingRoom({ commit }, consultingRoomId) {
  return new Promise((resolve, reject) => {
    let url = `/consulting-room/${consultingRoomId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateConsultingRoom(
  { commit },
  { data, consultingRoomId }
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando datos de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/consulting-room/${consultingRoomId}/`
    axios
      .patch(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene las redes sociales del consultorio.
 *
 * @param consultingRoomId Identificador del consultorio.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getSocialNetworks({ commit }, consultingRoomId) {
  return new Promise((resolve, reject) => {
    let url = `/social-networks/${consultingRoomId}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function updateSocialNetworks(
  { commit },
  { data, consultingRoomId }
) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando redes sociales de la empresa',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/social-networks/${consultingRoomId}/`
    axios
      .put(url, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite cargar las imágenes a la configuración de reportes del consultorio.
 *
 * @param {Object} obj.formData FormData con las imagenes del reporte.
 * @param {Object} obj.id Identificador del la tabla de reportes del consultorio
 */
// eslint-disable-next-line no-unused-vars
export async function updateConsultingRoomFormData(context, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/consulting-room-report/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function createInfographic({ commit }, { data }) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando Infografía',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/infographics/`
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Obtiene las infografias del consultorio.
 *
 * @param consultingRoomId Identificador del consultorio.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getInfographics({ commit }, page) {
  return new Promise((resolve, reject) => {
    let url = `/infographics/?page=${page}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene los tipos de consultorio.
 *
 * @param consultingRoomId Identificador del consultorio.
 *
 * @returns {Promise<Object>}
 */
// eslint-disable-next-line no-unused-vars
export async function getConsultingRoomType({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/consulting-room-type/'
    url = params !== null ? `${url}?${params}` : url

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite crear un nuevo consultorio con su respectivo tipo.
 *
 * @param {Object} data Objeto requerido para capturar un nuevo consultorio
 */
// eslint-disable-next-line no-unused-vars
export async function newConsultingRoomGeneralAction(context, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/consulting-room-general/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function saveConsultingRoomGeneralLogo(
  // eslint-disable-next-line no-unused-vars
  { commit },
  { formData, id }
) {
  return new Promise((resolve, reject) => {
    let url = `/consulting-room-general/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function validateEmail({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/users/validate-all-email/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * Permite crear un nuevo consultorio con su respectivo tipo.
 *
 * @param {Object} data Objeto requerido para capturar un nuevo consultorio
 */
// eslint-disable-next-line no-unused-vars
export async function newConsultingRoomAction(context, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/consulting-room-nutrition/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
