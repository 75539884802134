import { patientGeneralObject } from '@/models/patientsGeneral'
const _ = require('lodash')

export default {
  /**
   * Actualiza el estatus de avances de nuevo paciente general
   */
  setNewPatientGeneralProgress: (state, { section, card, status }) => {
    state.newPatientGeneralFormsProgress[section][card] = status
  },
  /**
   * Actualiza mensajes de errores de la pantalla de nuevo paciente general
   */
  setNewPatientGeneralErrors: (state, errors) => {
    state.newPatientGeneralErrors = _.cloneDeep(errors)
  },
  /**
   * Actualiza los datos del nuevo páciente general en el store.
   */
  setNewPatientGeneralData: (state, newPatientGeneral) => {
    state.newPatientGeneral = _.merge(
      state.newPatientGeneral,
      newPatientGeneral
    )
  },
  /**
   * Configura los documentos del paciente.
   */
  setPatientGeneralDocs: (state, formData) => {
    state.patientDocs = formData
  },
  setPatientGeneralPhoto: (state, formData) => {
    state.patientPhoto = formData
  },
  /**
   * Configura la lista de pacientes
   */
  setPatientsList: (state, patientsList) => {
    state.patientsGeneralList = patientsList
  },
  /**
   * Reinicia el objeto de nuevo paciente general y el progreso de las tarjetas
   */
  clearNewPatientGeneralData: (state) => {
    state.newPatientGeneral = _.cloneDeep(patientGeneralObject)
    state.newPatientGeneralFormsProgress = {
      generalInformation: {
        patientData: false,
      },
      inheritedFamilyHistory: {
        inheritedFamilyHistory: false,
      },
      nonPathologicalPersonalHistory: {
        nonPathologicalPersonalHistory: false,
      },
      pathologicalPersonalHistory: {
        pathologicalPersonalHistory: false,
      },
      obstetricianGynecology: {
        obstetricianGynecology: false,
      },
      docs: {
        ratings: false,
      },
      newPatientGeneralErrors: {},
      patientDocs: new FormData(),
      patientPhoto: new FormData(),
    }
  },
  setTotalPatientsPages: (state, pages) => {
    state.totalPatientsPages = pages
  },
  setPatientsPendientList: (state, patientsPendientList) => {
    state.patientsGeneralPendientList = patientsPendientList
  },
  /**
   * Prepara el objeto de paciente desde el servidor.
   * Formatea los catálogos para ser visualizados en el formulario de edición.
   */
  setUpdatePatientFromServer: (state, patient) => {
    let updatePatient = _.cloneDeep(patient)

    // Estado civil
    updatePatient.maritalStatusId = updatePatient.maritalStatus
      ? updatePatient.maritalStatus.id
      : 1
    delete updatePatient.maritalStatus
    // Historial clínico
    updatePatient.clinicalHistoryId = updatePatient.clinicalHistory.map(
      (item) => item.id
    )
    delete updatePatient.clinicalHistory
    updatePatient.ginecoAdd = updatePatient.gineco
    delete updatePatient.gineco
    updatePatient.analyticsConfigAdd = updatePatient.analyticsConfig
    delete updatePatient.analyticsConfig

    updatePatient.tracingElements = []

    state.updatePatient = _.cloneDeep(updatePatient)
  },
  clearUpdatePatientData: (state) => {
    state.updatePatient = _.cloneDeep(patientGeneralObject)
  },
  setUpdatePatientData: (state, updatePatient) => {
    state.updatePatient = _.merge(state.updatePatient, updatePatient)
  },
  /**
   * Configura la lista de pacientes
   */
  setTracingElements: (state, tracingList) => {
    state.updatePatient.tracingElements = tracingList
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
}
