import axios from 'axios'
import { selectedPatientObject } from '@/models/patients'

const _ = require('lodash')

export async function getPatientsList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/patients/'
    url = params !== null ? `${url}?${params}` : url
    // when get the patients list, Clean the actual patient
    state.selectedPatient = _.cloneDeep(selectedPatientObject)

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getPatientById({ commit }, id) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos del paciente',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/patients/${id}/`

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newPatientAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/patients/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite crear un nuevo paciente prospecto.
 *
 * @param {Object} data Objeto del paciente prospecto a crear.
 */
// eslint-disable-next-line no-unused-vars
export async function newProspectivePatientAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/patients/create-prospective-patient/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePatientAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/patients/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function savePatientDocs({ commit }, { formData }) {
  return new Promise((resolve, reject) => {
    let url = `/patient-docs/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePatientDocs({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/patient-docs/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Obtiene la lista de analytics de avances del candidato
 * @param {Number} id Identificador del paciente del que se obtendrán sus estadisticas
 */
// eslint-disable-next-line no-unused-vars
export async function getPatientAnalytics({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Calculando anlytics del paciente',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/patients-analytics/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
