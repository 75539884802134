<template>
  <footer id="main-footer">
    <div class="footer-header text-muted">
      <vs-row
        vs-align="center"
        vs-type="flex"
        vs-justify="space-around"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <a class="link" href="https://fygsolutions.com/" target="_blank">
            Made with ♥ by FyG Solutions
          </a>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <a
            class="link"
            href="https://fygsolutions.com/aviso-de-privacidad/"
            target="_blank"
          >
            Aviso de Privacidad
          </a>
        </vs-col>
      </vs-row>
    </div>
  </footer>
</template>

<script>
import { eventBus } from '@/plugins/event-bus'

export default {
  name: 'NavBar',
  data: function () {
    return {
      activeItem: 0,
    }
  },
  computed: {
    pageTitle: function () {
      return this.$route.meta.title
    },
  },
  beforeDestroy() {
    eventBus.$off('showSideBar', this.listener)
  },
  methods: {
    showSideBar: () => {
      eventBus.$emit('showSideBar')
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';

footer#main-footer {
  background: $dark;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #373a3c !important;
  text-align: center;

  .footer-header {
    font-size: 1rem;
    padding: 0.2rem 2rem;
    align-items: center;
    display: flex;
    min-height: $footer-height;
  }

  a.link {
    color: #fefefe;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #cdcdcd;
    }
  }
}
</style>
