<template>
  <div id="app">
    <navbar v-if="isLogged && showNavbar" />
    <main-sidebar v-if="isLogged" />
    <main>
      <router-view />
    </main>
    <app-footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import AppFooter from '@/components/Footer'
import MainSideBar from '@/components/MainSideBar'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    navbar: NavBar,
    'main-sidebar': MainSideBar,
    'app-footer': AppFooter,
  },
  computed: {
    ...mapState(['activeLoading', 'loadingPageMsg', 'showNavbar']),
    ...mapState('auth', ['user']),
    ...mapGetters('auth', { isLogged: 'isLogged' }),
  },
  watch: {
    activeLoading: function (isLoading) {
      // Don't use Arrow Functions for this case
      if (isLoading) {
        this.$vs.loading({
          text: this.loadingPageMsg,
        })
      } else {
        this.$vs.loading.close()
      }
    },
  },
  created() {
    // Valida si el usuario está logueado
    if (this.isLogged) {
      // eslint-disable-next-line max-len
      // Fuerza la carga de datos del usuario, consultorio, redes sociales y las configura en el storage
      this.getUserAction(this.user.username).then((userResponse) => {
        this.setUser(userResponse.user)
        // Setea los valores del consultorio
        this.getConsultingRoomAction(
          this.user.userprofile.consultingRoom.id
        ).then((response) => {
          this.setConsultingRoom(response)
        })
        // Carga las redes sociales relacionadas al consultorio del usuario
        this.getSocialNetworks(this.user.userprofile.consultingRoom.id).then(
          (response) => {
            this.setSocialNetworks(response)
          }
        )
      })
    }
  },
  methods: {
    ...mapActions('users', {
      getUserAction: 'getUser',
    }),
    ...mapActions('consultingroom', {
      getSocialNetworks: 'getSocialNetworks',
      getConsultingRoomAction: 'getConsultingRoom',
    }),
    ...mapMutations('auth', {
      setUser: 'setUser',
    }),
    ...mapMutations('consultingroom', {
      setConsultingRoom: 'setConsultingRoom',
      setSocialNetworks: 'setSocialNetworks',
    }),
  },
}
</script>
