export let patientGeneralObject = {
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  phone: '',
  gender: '',
  dateOfBirth: '',
  patientType: 'FP',
  maritalStatusId: 1,
  occupation: '',
  clinicalHistoryId: [],
  inheritedFamily: 'N',
  notesInheritedFamily: '',
  drinkAlcohol: 'N',
  notesAlcohol: '',
  isSmoker: 'N',
  notesTabaco: '',
  useDrugs: 'N',
  drugs: '',
  notesNonPathological: '',
  chronicDegenerative: 'N',
  notesChronicDegenerative: '',
  surgicals: 'N',
  notesSurgicals: '',
  allergic: 'N',
  allergy: '',
  trauma: 'N',
  notesTrauma: '',
  transfusions: 'N',
  notesTransfusions: '',
  ginecoobstetrico: 'N',
  notesGinecoobstetrico: '',
  ginecoAdd: {
    noGestas: null,
    noPartos: null,
    noCesareas: null,
    noAbortos: null,
    pregnancyComplications: '',
    periodType: 'IR',
    dateMenstruation: null,
    dysmenorrheaType: 'N',
    dysmenorrhea: '',
    notesCycle: '',
    notesContraceptives: '',
    startSexLife: null,
    noCouple: null,
    notesDiseases: '',
  },
  analyticsConfigAdd: {
    showPressure: true,
    showCardiacFrequency: true,
    showOxygen: true,
    showTemperature: true,
    showBreathingFrequency: true,
    showWeight: true,
    showSize: true,
    showImc: true,
  },
  patientPhoto: {
    id: null,
    photo: null,
  },
  photoId: null,
  patientDocsId: null,
  tracingElements: [],
}

export let selectedPatientGeneralObject = {
  id: 0,
  name: '',
  lastName: '',
  secondLastName: '',
  gender: '',
  maritalStatus: {
    id: null,
    status: '',
    isActive: null,
  },
  dateOfBirth: '',
  age: null,
  emailsContact: [],
  address: {
    id: null,
    street: '',
    postalCode: {
      id: null,
      postalCode: '',
      settlement: '',
      municipality: '',
      city: '',
      estate: '',
    },
  },
  patientDocument: {
    id: null,
    cv: null,
    summary: null,
    exam: null,
  },
  createdAt: null,
  modifiedBy: null,
}
