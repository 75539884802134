import axios from 'axios'
import { selectedPatientGeneralObject } from '@/models/patientsGeneral'

const _ = require('lodash')

// eslint-disable-next-line no-unused-vars
export async function savePatientGeneralDocs({ commit }, { formData }) {
  return new Promise((resolve, reject) => {
    let url = `/patient-docs-general/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePatientGeneralDocs({ commit }, { formData, id }) {
  return new Promise((resolve, reject) => {
    let url = `/patient-docs-general/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function updatePatientAction({ commit }, { data, id }) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/patients-general/${id}/`, data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function savePhoto(context, formData) {
  return new Promise((resolve, reject) => {
    let url = `/patient-photo-general/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function newPatientGeneralAction({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/patients-general/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getPatientsGeneralList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/patients-general/'
    url = params !== null ? `${url}?${params}` : url
    // when get the patients list, Clean the actual patient
    state.selectedPatientGeneral = _.cloneDeep(selectedPatientGeneralObject)

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getPatientById({ commit }, id) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos del paciente general',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/patients-general/${id}/`

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function validateEmail({ commit }, data) {
  return new Promise((resolve, reject) => {
    axios
      .post('/users/validate-email/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getGeneralPatientAnalytics({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Calculando anlytics del paciente',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/patients-general-analytics/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getGeneralPatientDynamicAnalyticsConf({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/patients-general-dynamic-analytics-conf/?patient_general_id=${id}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getGeneralPatientAnalyticsConf({ commit }, id) {
  return new Promise((resolve, reject) => {
    let url = `/patients-general-analytics-conf/?patient_general_id=${id}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function getGeneralPatientDynamicAnalytics({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Calculando anlytics del paciente',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    let url = `/patients-general-dynamic-analytics/${id}/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
