import { appointmentObject } from '@/models/appointments'
const _ = require('lodash')

export default {
  newAppointment: _.cloneDeep(appointmentObject),
  updateAppointment: _.cloneDeep(appointmentObject),
  updateScheduledAppointment: {},
  newAppointmentProgress: {
    patientData: false,
    calculoGrasa: false,
    nutritionalPlan: false,
    docsPlan: false,
    photosPlan: false,
    appointmentEvaluation: false,
  },
  searchParams: '',
  searchParamsScheduledAppointments: '',
  appointmentsList: [],
  scheduledAppointmentsList: [],
  totalAppointmentsPages: 0,
  totalScheduledAppointmentsPages: 0,
  appointmentDocs: new FormData(),
  appointmentPhotos: new FormData(),
}
