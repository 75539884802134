import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/router'
import catalogsRoutes from '@/modules/catalogs/router'
import patientsRoutes from '@/modules/patients/router'
import patientsGeneralRoutes from '@/modules/patientsgeneral/router'
import consultingRoomRoutes from '@/modules/consultingroom/router'
import appointmentsRoutes from '@/modules/appointments/router'
import usersRoutes from '@/modules/users/router'
import marketingRoutes from '@/modules/marketing/router'
import serverRoutes from '@/modules/server/router'
import appointmentsGeneralRoutes from '@/modules/appointmentsgeneral/router'
import prescriptionsRoutes from '@/modules/prescriptions/router'
import documentsPrintingRoutes from '@/modules/documentsprinting/router'
import store from '@/store'

Vue.use(VueRouter)

let baseRoutes = [
  {
    path: '*',
    name: 'index',
    redirect: {
      name: 'authLogin',
    },
  },
]

// noinspection JSCheckFunctionSignatures
const routes = baseRoutes.concat(
  authRoutes,
  catalogsRoutes,
  patientsRoutes,
  consultingRoomRoutes,
  appointmentsRoutes,
  usersRoutes,
  marketingRoutes,
  serverRoutes,
  appointmentsGeneralRoutes,
  patientsGeneralRoutes,
  prescriptionsRoutes,
  documentsPrintingRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresConsultingRoomAdmin = to.matched.some(
    (record) => record.meta.requiresConsultingRoomAdmin
  )
  const isLogged = store.getters['auth/isLogged']
  const isDoctorOrAdmin = store.getters['auth/isDoctorOrAdmin']
  const isDoctor = store.getters['auth/isDoctor']

  if (isLogged && to.path === '/auth/login') {
    const isConsultingNutrition1 = store.getters['auth/isConsultingNutrition']
    if (isConsultingNutrition1) {
      next({ name: 'patientsList' })
    } else {
      next({ name: 'patientsGeneralList' })
    }
  } else if (isLogged && to.path === '/pacientes') {
    const isConsultingNutrition3 = store.getters['auth/isConsultingNutrition']
    if (!isConsultingNutrition3) {
      next({ name: 'patientsGeneralList' })
    }
  }

  if (isLogged && to.path === '/auth/signup') {
    const isConsultingNutrition2 = store.getters['auth/isConsultingNutrition']
    if (isConsultingNutrition2) {
      next({ name: 'patientsList' })
    } else {
      next({ name: 'patientsGeneralList' })
    }
  }

  // validate the views that can only be visited by consultingroom admins
  if (requiresConsultingRoomAdmin && !isDoctorOrAdmin) {
    next({ name: 'MyProfile' })
  }
  if (isDoctor && to.path === '/consultorio') {
    next({ name: 'MyProfile' })
  }
  if (requiresAuth && !isLogged) {
    next({ name: 'authLogin' })
  }

  next()
})

router.afterEach(() => {
  const Stickedtooltips = document.querySelectorAll('.vs-tooltip')
  for (const tooltip of Stickedtooltips) {
    tooltip.remove()
  }
})
export default router
