export let newConsultingRoomObject = {
  name: '',
  email: '',
  phone: '',
  address: '',
  consultingRoomType: '',
  socialNetworks: {
    facebookUrl: '',
    instagramUrl: '',
    webUrl: '',
    twitterUrl: '',
    whatsappNumber: '',
  },
}
