import axios from 'axios'

/**
 * Envía la petición al servidor para dar de baja el email de la lista de marketing
 * @param data Object Objeto con la información del paciente
 * @returns {Promise<Object>}
 */
export async function unsubscribeEmail({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cancelando Subscripción',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/infographics-unsubscribe/unsubscribe/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
