const PatientsView = () =>
  import(
    /* webpackChunkName: "patients" */ '@/modules/patients/views/PatientsView'
  )
const UpdatePatientView = () =>
  import(
    /* webpackChunkName: "patients" */ '@/modules/patients/views/UpdatePatientView'
  )
const NewPatientView = () =>
  import(
    /* webpackChunkName: "patients" */ '@/modules/patients/views/NewPatientView'
  )
const NewProspectivePatientView = () =>
  import(
    /* webpackChunkName: "patients" */ '@/modules/patients/views/NewProspectivePatientView'
  )
const PatientProgressView = () =>
  import(
    /* webpackChunkName: "patients" */ '@/modules/patients/views/PatientProgressView'
  )

export default [
  {
    path: '/pacientes',
    name: 'patientsList',
    meta: {
      title: 'Lista de pacientes',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: PatientsView,
  },
  {
    path: '/pacientes/nuevo',
    name: 'newPatient',
    meta: {
      title: 'Nuevo paciente',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: NewPatientView,
  },
  {
    path: '/pacientes/nuevo-paciente-prospecto',
    name: 'newProspectPatient',
    meta: {
      title: 'Nuevo paciente prospecto',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: NewProspectivePatientView,
  },
  {
    path: '/avance-paciente/:id',
    name: 'patientProgress',
    meta: {
      title: 'Avances del paciente',
      requiresAuth: true,
      requiresConsultingRoomAdmin: false,
    },
    component: PatientProgressView,
  },
  {
    path: '/pacientes/:id',
    name: 'updatePatient',
    meta: {
      title: 'Editar paciente',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: UpdatePatientView,
  },
]
