import { roles, consultingRoomTypes } from '@/utils/utils'
/**
 * Get one product from storage
 * @param state
 */
export function isLogged(state) {
  return !!state.tokenAccess
}

export function isDoctor(state) {
  return state.user.userprofile.role === roles.DOCTOR
}

export function isDoctorOrAdmin(state) {
  return (
    state.user.userprofile.role === roles.ADMIN ||
    state.user.userprofile.role === roles.DOCTOR
  )
}

export function getUsername(state) {
  return state.user.username
}

export function isConsultingNutrition(state) {
  return (
    state.user.userprofile.consultingRoom.consultingRoomType.id ===
    consultingRoomTypes.NUTRICION
  )
}
