import axios from 'axios'

/**
 * Retorna la lista de los documentos de impresión rápida.
 *
 * @param {String} params query params (Opcionales)
 */
// eslint-disable-next-line no-unused-vars
export async function getDocumentsPrintingList({ state }, params = null) {
  return new Promise((resolve, reject) => {
    let url = '/documents-printing/'
    url = params !== null ? `${url}?${params}` : url

    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function saveDocumentPrinting(context, formData) {
  return new Promise((resolve, reject) => {
    let url = `/documents-printing/`
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Permite eliminar un documento de la base de datos
 * @param {Number} id Identificar del documento que se eliminará
 */
export async function deleteDocumentPrintingAction({ commit }, id) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Eliminando documento...',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/documents-printing/${id}/`

    axios
      .delete(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
