const LoginView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/LoginView')
const RegisterView = () =>
  import(/* webpackChunkName: "auth" */ '@/modules/auth/views/RegisterView')
const RestorePasswordEmailView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordEmailView'
  )
const RestorePasswordView = () =>
  import(
    /* webpackChunkName: "auth" */ '@/modules/auth/views/RestorePasswordView'
  )

export default [
  {
    path: '/auth/login',
    name: 'authLogin',
    meta: {
      title: 'Iniciar Sesión',
      requiresAuth: false,
      requiresConsultingRoomAdmin: false,
    },
    component: LoginView,
  },
  {
    path: '/auth/signup',
    name: 'authRegister',
    meta: {
      title: 'Registro',
      requiresAuth: false,
      requiresConsultingRoomAdmin: false,
    },
    component: RegisterView,
  },
  {
    path: '/password-recovery',
    name: 'passwordRecovery',
    meta: {
      title: 'Recuperar contraseña',
      requiresAuth: false,
      requiresConsultingRoomAdmin: false,
    },
    component: RestorePasswordEmailView,
  },
  {
    path: '/password_reset',
    name: 'passwordReset',
    meta: {
      title: 'Coloque sus nueva contraseña',
      requiresAuth: false,
      requiresConsultingRoomAdmin: false,
    },
    component: RestorePasswordView,
  },
]
