import axios from 'axios'

export async function getToken({ commit }, user) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Iniciando sesión',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    axios
      .post('/auth/token/', user)
      .then((response) => {
        const data = response['data']
        const tokenAccess = data['access']
        const tokenRefresh = data['refresh']
        commit('setUserTokens', {
          tokenAccess,
          tokenRefresh,
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function refreshToken({ commit, state }) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Iniciando sesión',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    axios
      .post('/auth/token/refresh/', { refresh: state.tokenRefresh })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

// eslint-disable-next-line object-curly-newline
export async function logout({ commit }) {
  commit('logout')
}

export async function singUp({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Registrando usuario',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/signup/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function updatePassword({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Actualizando contraseña',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .put('/auth/update_password/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Permite enviar un correo para recuperar la cuenta.
 *
 * @param {String} data.email Email del que se requiere recuperar la contraseña
 */
// eslint-disable-next-line no-unused-vars
export async function recoveryPasswordByEmail({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando email de recuperación',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/recover-password-email/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

/**
 * Resetea las credenciales de la cuenta.
 *
 * @param {String} data.password Nueva contraseña
 * @param {String} data.passwordConfirmation Confirmación de la nueva contraseña
 * @param {String} data.token JWT Token
 */
// eslint-disable-next-line no-unused-vars
export async function recoveryPassword({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Cambiando credenciales',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/recover-password/', data)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function userActivation({ commit }, token) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Activando cuenta',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/verify/', { token })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function invitePatient({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando Email',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/invitation/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function resendVerification({ commit }, data) {
  // Set loading page animation
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Enviando Email',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    axios
      .post('/auth/resend-verify/', data)
      .then((response) => {
        resolve(response !== undefined)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
