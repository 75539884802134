import { appointmentObject } from '@/models/appointments'
import utils from '@/utils/utils'

const _ = require('lodash')

export default {
  /**
   * Actualiza los datos de la nueva cita
   */
  setNewAppointmentData: (state, newAppointment) => {
    state.newAppointment = _.merge(state.newAppointment, newAppointment)
  },
  /**
   * Prepara el objeto de cita desde el servidor.
   * Formatea los campos para ser visualizados en el formulario de edición.
   */
  setUpdateAppointmentFromServer: (state, updateAppointment) => {
    let dt = ''
    if (updateAppointment.appointmentTime) {
      dt = utils.formatDateTime(updateAppointment.appointmentTime)
    } else {
      dt = updateAppointment.appointmentTime
    }

    updateAppointment.nutritionalPlanAdd = updateAppointment.nutritionalPlan
    delete updateAppointment.nutritionalPlan

    state.updateAppointment = _.merge(
      state.updateAppointment,
      updateAppointment,
      { appointmentTime: dt }
    )
  },
  /**
   * Actualiza los datos de la cita a editar
   */
  setUpdateAppointmentData: (state, updateAppointment) => {
    state.updateAppointment = _.merge(
      state.updateAppointment,
      updateAppointment
    )
  },
  /**
   * Actualiza los datos de la cita a editar
   */
  setUpdateScheduledAppointment: (state, updateScheduledAppointment) => {
    state.updateScheduledAppointment = _.merge(
      state.updateScheduledAppointment,
      updateScheduledAppointment
    )
  },
  /**
   * Configura el progreso para la vista de nuevos pacientes.
   */
  setNewAppointmentProgress: (state, { card, status }) => {
    state.newAppointmentProgress[card] = status
  },
  /**
   * Reinicia el objeto de la cita a editar
   */
  clearUpdateAppointmentData: (state) => {
    state.updateAppointment = _.cloneDeep(appointmentObject)
  },
  /**
   * Reiniciar el objeto del nuevo paciente
   */
  clearNewAppointmentData: (state) => {
    state.newAppointment = _.cloneDeep(appointmentObject)
    state.newAppointmentProgress = {
      patientData: false,
      calculoGrasa: false,
      nutritionalPlan: false,
      docsPlan: false,
      photosPlan: false,
      appointmentEvaluation: false,
    }
  },
  /**
   * Configura las citas del paciente que ya se concretaron.
   */
  setAppointmentsList: (state, appointmentsList) => {
    state.appointmentsList = appointmentsList
  },
  /**
   * Configura el FormData para los documentos de la cita.
   */
  setAppointmentDocs: (state, formData) => {
    state.appointmentDocs = formData
  },
  /**
   * Configura el FormData para las fotos de la cita.
   */
  setAppointmentPhotos: (state, formData) => {
    state.appointmentPhotos = formData
  },
  /**
   * Configura las citas del paciente pendientes
   */
  setScheduledAppointmentsList: (state, scheduledAppointmentsList) => {
    state.scheduledAppointmentsList = scheduledAppointmentsList
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setSearchParamsScheduledAppointments: (
    state,
    searchParamsScheduledAppointments
  ) => {
    state.searchParamsScheduledAppointments = searchParamsScheduledAppointments
  },
  /**
   * Configura el total de páginas en la lista de citas capturadas
   */
  setTotalAppointmentsPages: (state, pages) => {
    state.totalAppointmentsPages = pages
  },
  /**
   * Configura el total de páginas en la lista de citas agendadas.
   */
  setTotalScheduledAppointmentsPages: (state, pages) => {
    state.totalScheduledAppointmentsPages = pages
  },
}
