import {
  patientGeneralObject,
  selectedPatientGeneralObject,
} from '@/models/patientsGeneral'

const _ = require('lodash')

export default {
  newPatientGeneral: _.cloneDeep(patientGeneralObject),
  updatePatient: _.cloneDeep(patientGeneralObject),
  selectedPatientGeneral: _.cloneDeep(selectedPatientGeneralObject),
  newPatientGeneralFormsProgress: {
    generalInformation: {
      patientData: false,
    },
    inheritedFamilyHistory: {
      inheritedFamilyHistory: false,
    },
    nonPathologicalPersonalHistory: {
      nonPathologicalPersonal: false,
    },
    pathologicalPersonalHistory: {
      pathologicalPersonal: false,
    },
    obstetricianGynecology: {
      obstetricianGynecology: false,
    },
    docs: {
      ratings: false,
    },
  },
  newPatientGeneralErrors: {},
  updatePatientErrors: {},
  patientDocs: new FormData(),
  patientPhoto: new FormData(),
  patientDocsId: 0,
  patientsGeneralList: [],
  patientsGeneralPendientList: [],
  searchParams: '',
  totalPatientsPages: 1,
}
