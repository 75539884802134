<template>
  <vs-navbar id="main-navbar" class="app-nav" color="dark">
    <div slot="title" vs-w="12">
      <vs-navbar-title>
        <vs-row vs-justify="center">
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button
              color="dark"
              type="filled"
              icon="menu"
              @click="showSideBar"
            >
              <span class="page-title" v-text="pageTitle" />
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-navbar-title>
    </div>
    <div v-if="web">
      <vs-navbar-item index="0">
        <a class="link" target="_blank" :href="socialNetworks.webUrl"
          ><font-awesome-icon :icon="['fas', 'globe']" size="lg"
        /></a>
      </vs-navbar-item>
    </div>
    <div v-if="facebook">
      <vs-navbar-item index="1">
        <a class="link" target="_blank" :href="socialNetworks.facebookUrl"
          ><font-awesome-icon :icon="['fab', 'facebook']" size="lg"
        /></a>
      </vs-navbar-item>
    </div>
    <div v-if="twitter">
      <vs-navbar-item index="2">
        <a class="link" target="_blank" :href="socialNetworks.twitterUrl"
          ><font-awesome-icon :icon="['fab', 'twitter']" size="lg"
        /></a>
      </vs-navbar-item>
    </div>
    <div v-if="instagram">
      <vs-navbar-item index="3">
        <a class="link" target="_blank" :href="socialNetworks.instagramUrl"
          ><font-awesome-icon :icon="['fab', 'instagram']" size="lg"
        /></a>
      </vs-navbar-item>
    </div>
    <div v-if="whatsapp">
      <vs-navbar-item index="4">
        <a
          class="link"
          target="_blank"
          :href="
            'https://wa.me/' + socialNetworks.whatsappNumber + '?text=Hola'
          "
          ><font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"
        /></a>
      </vs-navbar-item>
    </div>
  </vs-navbar>
</template>

<script>
import { eventBus } from '@/plugins/event-bus'
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  data: function () {
    return {
      activeItem: 0,
    }
  },
  computed: {
    ...mapState('auth', { token: 'tokenAccess' }),
    ...mapState('consultingroom', { socialNetworks: 'socialNetworks' }),
    pageTitle: function () {
      return this.$route.meta.title
    },
    facebook: function () {
      return this.socialNetworks.facebookUrl !== ''
    },
    web: function () {
      return this.socialNetworks.webUrl !== ''
    },
    twitter: function () {
      return this.socialNetworks.twitterUrl !== ''
    },
    instagram: function () {
      return this.socialNetworks.instagramUrl !== ''
    },
    whatsapp: function () {
      return this.socialNetworks.whatsappNumber != ''
    },
  },
  beforeDestroy() {
    eventBus.$off('showSideBar', this.listener)
  },
  methods: {
    showSideBar: () => {
      eventBus.$emit('showSideBar')
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';
#main-navbar {
  color: rgb(255, 255, 255);
  height: $navbar-height;
  position: fixed;
  top: 0;
  left: 0;
  .page-title {
    margin-left: 1rem;
    font-size: 1.2rem;
  }
  .vs-navbar--btn-responsive {
    display: none;
  }
  a.link {
    color: #fefefe;
    font-weight: 300;
    transition: all 0.3s ease;
    &:hover {
      color: #cdcdcd;
    }
  }
}
</style>
