import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import VueAxios from 'vue-axios'

require('promise.prototype.finally').shim()

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8001/api/'
    : process.env.VUE_APP_API_BACK

axios.defaults.baseURL = baseURL

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const tokenAccess = store.state['auth']['tokenAccess']
    switch (config.url) {
      case `/auth/token/`:
      case `/auth/verify/`:
        return config
    }
    if (tokenAccess) {
      config.headers['Authorization'] = `Bearer ${tokenAccess}`
    }
    // Set locale headers
    config.headers['Accept-Language'] = 'es-es'
    return config
  },
  (error) => {
    // noinspection JSIgnoredPromiseFromCall
    Promise.reject(error)
  }
)

// Response interceptor

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      originalRequest.url === `/auth/token/refresh/`
    ) {
      store.commit('auth/clearTokens')

      // noinspection JSIgnoredPromiseFromCall
      router
        .push({
          name: 'authLogin',
        })
        .catch(() => {})
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('auth/refreshToken').then((res) => {
        if (res.status === 200) {
          const tokenAccess = res.data['access']
          store.commit('auth/setTokenAccess', tokenAccess)
          return axios(originalRequest)
        }
      })
    }
    return Promise.reject(error)
  }
)
Vue.use(VueAxios, axios)
