const NewPatientGeneralView = () =>
  import(
    /* webpackChunkName: "patientsgeneral" */ '@/modules/patientsgeneral/views/NewPatientGeneralView'
  )
const PatientsGeneralView = () =>
  import(
    /* webpackChunkName: "patientsgeneral" */ '@/modules/patientsgeneral/views/PatientsGeneralView'
  )
const UpdatePatientGeneralView = () =>
  import(
    /* webpackChunkName: "patientsgeneral" */ '@/modules/patientsgeneral/views/UpdatePatientGeneralView'
  )
const PatientGeneralProgressView = () =>
  import(
    /* webpackChunkName: "patientsgeneral" */ '@/modules/patientsgeneral/views/PatientGeneralProgressView'
  )

export default [
  {
    path: '/pacientes-generales',
    name: 'patientsGeneralList',
    meta: {
      title: 'Lista de pacientes generales',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: PatientsGeneralView,
  },
  {
    path: '/nuevo-paciente-general',
    name: 'newPatientGeneral',
    meta: {
      title: 'Nuevo paciente general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: NewPatientGeneralView,
  },
  {
    path: '/pacientes-generales/:id',
    name: 'updatePatientGeneral',
    meta: {
      title: 'Editar paciente general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: UpdatePatientGeneralView,
  },
  {
    path: '/avance-paciente-general/:id',
    name: 'patientGeneralProgress',
    meta: {
      title: 'Avances del paciente general',
      requiresAuth: true,
      requiresConsultingRoomAdmin: false,
    },
    component: PatientGeneralProgressView,
  },
]
