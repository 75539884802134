const EmailUnsubscribe = () =>
  import(
    /* webpackChunkName: "marketing" */ '@/modules/marketing/views/EmailUnsubscribeView'
  )

export default [
  {
    path: '/marketing/unsubscribe',
    name: 'emailUnsubscribe',
    meta: {
      title: 'Baja de email marketing',
      requiresAuth: false,
      requiresConsultingRoomAdmin: false,
    },
    component: EmailUnsubscribe,
  },
]
