import { patientObject, prospectivePatientObject } from '@/models/patients'
const _ = require('lodash')

export default {
  /**
   * Prepara el objeto de paciente desde el servidor.
   * Formatea los catálogos para ser visualizados en el formulario de edición.
   */
  setUpdatePatientFromServer: (state, patient) => {
    let updatePatient = _.cloneDeep(patient)

    // Estado civil
    updatePatient.maritalStatusId = updatePatient.maritalStatus
      ? updatePatient.maritalStatus.id
      : 1
    delete updatePatient.maritalStatus
    // Historial clínico
    updatePatient.clinicalHistoryId = updatePatient.clinicalHistory.map(
      (item) => item.id
    )
    delete updatePatient.clinicalHistory

    // Consumo de alcohol
    updatePatient.alcoholConsumptionId = updatePatient.alcoholConsumption
      ? updatePatient.alcoholConsumption.id
      : 1

    delete updatePatient.alcoholConsumption

    // Consutmo de sal
    updatePatient.saltConsumptionId = updatePatient.saltConsumption
      ? updatePatient.saltConsumption.id
      : 1
    delete updatePatient.saltConsumption

    // Consutmo de azúcar
    updatePatient.sugarConsumptionId = updatePatient.saltConsumption
      ? updatePatient.saltConsumption.id
      : 1
    delete updatePatient.sugarConsumption

    // Consumo de café
    updatePatient.coffeeConsumptionId = updatePatient.coffeeConsumption.map(
      (item) => item.id
    )
    delete updatePatient.coffeeConsumption

    // Vitaminas
    updatePatient.vitaminsId = updatePatient.vitamins.map((item) => item.id)
    delete updatePatient.vitamins

    // Minerales
    updatePatient.mineralsId = updatePatient.minerals.map((item) => item.id)
    delete updatePatient.minerals

    // Minerales
    updatePatient.sportsSupplementsId = updatePatient.sportsSupplements.map(
      (item) => item.id
    )
    delete updatePatient.sportsSupplements

    // Hábitos deportivos
    updatePatient.exerciseHabitsAdd = updatePatient.exerciseHabits
    delete updatePatient.exerciseHabits

    // Objetivos
    updatePatient.patientObjectivesAdd = updatePatient.patientObjectives
    delete updatePatient.patientObjectives

    // Objetivos salud/nutrición
    updatePatient.patientObjectivesAdd.objectivesId = updatePatient.patientObjectivesAdd.objectives.map(
      (item) => item.id
    )
    delete updatePatient.patientObjectivesAdd.objectives

    updatePatient.patientDocsId = updatePatient.patientDocument.id

    state.updatePatient = _.cloneDeep(updatePatient)
  },
  /**
   * Actualiza los datos del nuevo páciente en el store.
   */
  setNewPatientData: (state, newPatient) => {
    state.newPatient = _.merge(state.newPatient, newPatient)
  },
  /**
   * Actualiza los datos del nuevo páciente prospecto en el store.
   */
  setNewProspectivePatientData: (state, newProspectivePatient) => {
    state.newProspectivePatient = _.merge(
      state.newProspectivePatient,
      newProspectivePatient
    )
  },
  /**
   * Actualiza los datos del nuevo páciente en el store.
   */
  setUpdatePatientData: (state, updatePatient) => {
    state.updatePatient = _.merge(state.updatePatient, updatePatient)
  },
  /**
   * Setea el paciente activo.
   */
  setSelectedPatient: (state, selectedPatient) => {
    state.selectedPatient = selectedPatient
  },
  /**
   * Reinicia el objeto de nuevo paciente y el progreso de las tarjetas
   */
  clearNewPatientData: (state) => {
    state.newPatient = _.cloneDeep(patientObject)
    state.newPatientFormsProgress = {
      generalInformation: {
        patientData: false,
      },
      clinicalHistory: {
        clinicalHistory: false,
      },
      consumptionHabits: {
        consumptionHabits: false,
      },
      feedingHabits: {
        feedingHabits: false,
      },
      exerciseHabits: {
        exerciseHabits: false,
      },
      objectives: {
        objectives: false,
      },
    }
  },
  /**
   * Reinicia el objeto de nuevo paciente prospecto
   */
  clearNewProspectivePatientData: (state) => {
    state.newProspectivePatient = _.cloneDeep(prospectivePatientObject)
  },
  /**
   * Reinicia el objeto de actualizar paciente
   */
  clearUpdatePatientData: (state) => {
    state.updatePatient = _.cloneDeep(patientObject)
  },
  /**
   * Actualiza el estatus de avances de nuevo paciente
   */
  setNewPatientProgress: (state, { section, card, status }) => {
    state.newPatientFormsProgress[section][card] = status
  },
  /**
   * Actualiza mensajes de errores de la pantalla de nuevo paciente
   */
  setNewPatientErrors: (state, errors) => {
    state.newPatientErrors = _.cloneDeep(errors)
  },
  /**
   * Actualiza mensajes de errores en la pantalla de actualizar paciente
   */
  setUpdatePatientErrors: (state, errors) => {
    state.updatePatientErrors = _.cloneDeep(errors)
  },
  /**
   * Configura la lista de pacientes
   */
  setPatientsList: (state, patientsList) => {
    state.patientsList = patientsList
  },
  /**
   * Configura la lista de pacientes pendientes
   */
  setPatientsPendientList: (state, patientsPendientList) => {
    state.patientsPendientList = patientsPendientList
  },
  /**
   * Configura los documentos del paciente.
   */
  setPatientDocs: (state, formData) => {
    state.patientDocs = formData
  },
  setNewPatientRatings: (state, newPatient) => {
    state.newPatient = _.merge(state.newPatient, {
      patientRatingAdd: newPatient.patientRatingAdd,
    })
  },
  setUpdatePatientRatings: (state, updatePatient) => {
    state.updatePatient = _.merge(state.updatePatient, {
      patientFollowUp: updatePatient.patientFollowUp,
    })
  },
  setUpdatePatientFollowUp: (state, updatePatient) => {
    state.updatePatient = _.merge(state.updatePatient, {
      patientFollowUpAdd: updatePatient.patientRatingAdd,
    })
  },
  setSearchParams: (state, searchParams) => {
    state.searchParams = searchParams
  },
  setTotalPatientsPages: (state, pages) => {
    state.totalPatientsPages = pages
  },
}
