import { appointmentGeneralObject } from '@/models/appointmentsGeneral'
const _ = require('lodash')

export default {
  newAppointmentGeneral: _.cloneDeep(appointmentGeneralObject),
  updateAppointmentGeneral: _.cloneDeep(appointmentGeneralObject),
  systemsDetailsAdd: [],
  segmentalDetailsAdd: [],
  medicinesAdd: [],
  laboratoriesAdd: [],
  searchParams: '',
  searchParamsCanceledAppointments: '',
  searchParamsScheduledAppointments: '',
  appointmentsGeneralList: [],
  scheduledAppointmentsList: [],
  appointmentGeneralDocs: new FormData(),
  totalAppointmentsPages: 0,
  totalScheduledAppointmentsPages: 0,
  tracingDetailsAdd: [],
  updateAppointmentGeneralProgress: {
    appointmentFinal: false,
  },
}
