export let patientObject = {
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  phone: '',
  gender: '',
  dateOfBirth: '',
  patientType: 'FP',
  maritalStatusId: 1,
  occupation: '',
  clinicalHistoryId: [],
  intolerance: '',
  allergy: '',
  isSmoker: 'N',
  previousNutritionalTreatments: '',
  drugs: '',
  medicines: '',
  coffeeConsumptionId: [],
  alcoholConsumption: 1,
  sugarConsumptionId: 1,
  saltConsumptionId: 1,
  niceFoods: '',
  unpleasantFoods: '',
  foodFlavor: 'D',
  litersOfWater: 0,
  litersOfSoda: 0,
  drinkSoda: 'N',
  vitaminsId: [],
  mineralsId: [],
  sportsSupplementsId: [],
  otherSupplements: '',
  breakfast: '',
  collationFirst: '',
  food: '',
  collationSecond: '',
  dinner: '',
  wakingTime: '',
  timeToSleep: '',
  exerciseHabitsAdd: [
    {
      activity: '',
      timeInMinutes: 1,
      activityTime: '00:00',
      timesAWeek: 1,
      yearsPracticing: 0,
    },
  ],
  patientObjectivesAdd: {
    objectivesId: [],
    oneMonth: '',
    threeMonth: '',
    sixMonth: '',
    targetWeight: 50,
    currentWeightComfort: 5,
    motivationLevel: 5,
    levelOfCommitment: 5,
  },
  patientDocsId: null,
}

export let selectedPatientObject = {
  id: 0,
  name: '',
  lastName: '',
  secondLastName: '',
  gender: '',
  minimumSalaryExpectation: '',
  maximumSalaryExpectation: '',
  maritalStatus: {
    id: null,
    status: '',
    isActive: null,
  },
  availabilityToStart: '',
  dateOfBirth: '',
  age: null,
  jobVacancy: {
    id: null,
    name: '',
    isActive: true,
  },
  nationality: {
    id: null,
    name: '',
    isActive: true,
  },
  recruitingMedium: {
    id: null,
    name: '',
    isActive: true,
  },
  emailsContact: [],
  address: {
    id: null,
    street: '',
    postalCode: {
      id: null,
      postalCode: '',
      settlement: '',
      municipality: '',
      city: '',
      estate: '',
    },
  },
  generalInformationComments: '',
  scholarship: [],
  language: [],
  certification: [],
  course: [],
  studiesComments: '',
  jobExperience: [],
  quality: [],
  professionalExperienceComments: null,
  opportunityArea: [],
  externalPerceptions: [],
  personalityComments: '',
  patientDocument: {
    id: null,
    cv: null,
    summary: null,
    exam: null,
  },
  patientRating: {
    id: null,
    examResult: 5,
    attitudeRating: 0,
    aptitudeRating: 0,
    globalRating: 0,
  },
  patientFollowUp: {
    id: null,
    status: '',
    observations: '',
  },
  createdAt: null,
  modifiedBy: null,
}

export let prospectivePatientObject = {
  name: '',
  lastName: '',
  secondLastName: '',
  email: '',
  phone: '',
  gender: '',
  dateOfBirth: '',
  patientType: 'PP',
}
