const _ = require('lodash')
const { DateTime } = require('luxon')

export function finalNewPatient(store) {
  let finalPatient = _.cloneDeep(store.newPatient)
  finalPatient.dateOfBirth = DateTime.fromISO(
    finalPatient.dateOfBirth
  ).toFormat('yyyy-MM-dd')

  finalPatient.wakingTime = DateTime.fromISO(finalPatient.wakingTime).toFormat(
    'T'
  )

  finalPatient.timeToSleep = DateTime.fromISO(
    finalPatient.timeToSleep
  ).toFormat('T')

  finalPatient.exerciseHabitsAdd = finalPatient.exerciseHabitsAdd.map(
    (item) => {
      item.activityTime = DateTime.fromISO(item.activityTime).toFormat('T')
      return item
    }
  )
  return finalPatient
}

export function finalNewProspectivePatient(store) {
  let finalProspectivePatient = _.cloneDeep(store.newProspectivePatient)
  finalProspectivePatient.dateOfBirth = DateTime.fromISO(
    finalProspectivePatient.dateOfBirth
  ).toFormat('yyyy-MM-dd')

  return finalProspectivePatient
}

export function finalUpdatePatient(store) {
  let finalPatient = _.cloneDeep(store.updatePatient)
  finalPatient.dateOfBirth = DateTime.fromISO(
    finalPatient.dateOfBirth
  ).toFormat('yyyy-MM-dd')

  finalPatient.wakingTime = DateTime.fromISO(finalPatient.wakingTime).toFormat(
    'T'
  )

  finalPatient.timeToSleep = DateTime.fromISO(
    finalPatient.timeToSleep
  ).toFormat('T')

  finalPatient.exerciseHabitsAdd = finalPatient.exerciseHabitsAdd.map(
    (item) => {
      item.activityTime = DateTime.fromISO(item.activityTime).toFormat('T')
      return item
    }
  )
  return finalPatient
}

export function getNewPatientErrors(store) {
  let finalNewPatientErrors = {
    generalInformation: {
      patientData: [],
      contact: [],
      address: [],
    },
  }
  // Datos del paciente
  if (store.newPatientErrors.hasOwnProperty('name')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Nombre',
      msg: store.newPatientErrors.name,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('lastName')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.newPatientErrors.lastName,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('secondLastName')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.newPatientErrors.secondLastName,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('minimumSalaryExpectation')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Expectativa salarial mínima',
      msg: store.newPatientErrors.minimumSalaryExpectation,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('maximumSalaryExpectation')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Expectativa salarial máxima',
      msg: store.newPatientErrors.maximumSalaryExpectation,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('employmentStatusId')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Estatus laboral',
      msg: store.newPatientErrors.employmentStatusId,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('jobVacancyId')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Puesto al que aplica',
      msg: store.newPatientErrors.jobVacancyId,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('dateOfBirth')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Fechande nacimiento',
      msg: store.newPatientErrors.dateOfBirth,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('nationalityId')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Nacionalidad',
      msg: store.newPatientErrors.nationalityId,
    })
  }
  if (store.newPatientErrors.hasOwnProperty('recruitingMediumId')) {
    finalNewPatientErrors.generalInformation.patientData.push({
      name: 'Medio de reclutamiento',
      msg: store.newPatientErrors.recruitingMediumId,
    })
  }
  // Contacto
  if (store.newPatientErrors.hasOwnProperty('emailsContactAdd')) {
    finalNewPatientErrors.generalInformation.contact.push({
      name: 'Email de contacto',
      msg: store.newPatientErrors.emailsContactAdd[0],
    })
  }
  if (store.newPatientErrors.hasOwnProperty('phonesContactAdd')) {
    finalNewPatientErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.newPatientErrors.phonesContactAdd[0],
    })
  }

  // Dirección
  if (store.newPatientErrors.hasOwnProperty('addressAdd')) {
    finalNewPatientErrors.generalInformation.address.push({
      name: 'Dirección',
      msg: store.newPatientErrors.addressAdd,
    })
  }

  return finalNewPatientErrors
}

export function getUpdatePatientErrors(store) {
  let finalUpdatePatientErrors = {
    generalInformation: {
      patientData: [],
      contact: [],
      address: [],
    },
  }
  // Datos del paciente
  if (store.updatePatientErrors.hasOwnProperty('name')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Nombre',
      msg: store.updatePatientErrors.name,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('lastName')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.updatePatientErrors.lastName,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('secondLastName')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Apellido Paterno',
      msg: store.updatePatientErrors.secondLastName,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('minimumSalaryExpectation')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Expectativa salarial mínima',
      msg: store.updatePatientErrors.minimumSalaryExpectation,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('maximumSalaryExpectation')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Expectativa salarial máxima',
      msg: store.updatePatientErrors.maximumSalaryExpectation,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('employmentStatusId')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Estatus laboral',
      msg: store.updatePatientErrors.employmentStatusId,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('jobVacancyId')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Puesto al que aplica',
      msg: store.updatePatientErrors.jobVacancyId,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('dateOfBirth')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Fechande nacimiento',
      msg: store.updatePatientErrors.dateOfBirth,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('nationalityId')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Nacionalidad',
      msg: store.updatePatientErrors.nationalityId,
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('recruitingMediumId')) {
    finalUpdatePatientErrors.generalInformation.patientData.push({
      name: 'Medio de reclutamiento',
      msg: store.updatePatientErrors.recruitingMediumId,
    })
  }
  // Contacto
  if (store.updatePatientErrors.hasOwnProperty('emailsContactAdd')) {
    finalUpdatePatientErrors.generalInformation.contact.push({
      name: 'Email de contacto',
      msg: store.updatePatientErrors.emailsContactAdd[0],
    })
  }
  if (store.updatePatientErrors.hasOwnProperty('phonesContactAdd')) {
    finalUpdatePatientErrors.generalInformation.contact.push({
      name: 'Teléfono de contacto',
      msg: store.updatePatientErrors.phonesContactAdd[0],
    })
  }

  // Dirección
  if (store.updatePatientErrors.hasOwnProperty('addressAdd')) {
    finalUpdatePatientErrors.generalInformation.address.push({
      name: 'Dirección',
      msg: store.updatePatientErrors.addressAdd,
    })
  }

  return finalUpdatePatientErrors
}
