export let appointmentGeneralObject = {
  id: '',
  patient: '',
  reason: '',
  suffering: '',
  pressure: '',
  cardiacFrequency: '',
  oxygen: '',
  temperature: '',
  breathingFrequency: '',
  weight: '',
  size: '',
  imc: '',
  laboratories: '',
  cabinetStudy: '',
  diagnosticImpression: '',
  cie10: '',
  observations: '',
  additionalInformation: '',
  appointmentGeneralStatus: 'CO',
  systemsDetailsAdd: [],
  segmentalDetailsAdd: [],
  medicinesAdd: [],
  laboratoriesAdd: [],
  appointmentDocsId: null,
  generalAppointmentDocuments: {
    id: 0,
    docOne: '',
    docTwo: '',
    docThree: '',
    docFour: '',
  },
  generalAppointmentReports: {
    file: '',
  },
  prescriptionId: null,
  tracingValues: [],
  showVitalSigns: 'S',
}
