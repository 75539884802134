import {
  patientObject,
  selectedPatientObject,
  prospectivePatientObject,
} from '@/models/patients'

const _ = require('lodash')

export default {
  newPatient: _.cloneDeep(patientObject),
  newProspectivePatient: _.cloneDeep(prospectivePatientObject),
  updatePatient: _.cloneDeep(patientObject),
  selectedPatient: _.cloneDeep(selectedPatientObject),
  newPatientFormsProgress: {
    generalInformation: {
      patientData: false,
    },
    clinicalHistory: {
      clinicalHistory: false,
    },
    consumptionHabits: {
      consumptionHabits: false,
      supplementation: false,
    },
    feedingHabits: {
      feedingHabits: false,
    },
    exerciseHabits: {
      exerciseHabits: false,
    },
    objectives: {
      objectives: false,
    },
    docs: {
      ratings: false,
    },
  },
  newPatientErrors: {},
  updatePatientErrors: {},
  patientsList: [],
  patientsPendientList: [],
  patientDocs: new FormData(),
  searchParams: '',
  totalPatientsPages: 1,
}
