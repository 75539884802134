const CaptureAppointmentView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointments/views/CaptureAppointmentView'
  )
const EditAppointmentView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointments/views/EditAppointmentView'
  )
const PatientAppointmentsView = () =>
  import(
    /* webpackChunkName: "appointments" */ '@/modules/appointments/views/PatientAppointmentsView'
  )

export default [
  {
    path: '/capturar-cita/:id',
    name: 'captureAppointment',
    meta: {
      title: 'Capturar cita',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: CaptureAppointmentView,
  },
  {
    path: '/editar-cita/:id',
    name: 'editAppointment',
    meta: {
      title: 'Editar cita',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: EditAppointmentView,
  },
  {
    path: '/citas-del-paciente/:patientId/',
    name: 'patientAppointments',
    meta: {
      title: 'Citas del paciente',
      requiresAuth: true,
      requiresConsultingRoomAdmin: false,
    },
    component: PatientAppointmentsView,
  },
]
