const DocumentsPrintingView = () =>
  import(
    /* webpackChunkName: "documents printing" */ '@/modules/documentsprinting/views/DocumentsPrintingView'
  )

export default [
  {
    path: '/documentos-de-impresion-rapida/',
    name: 'DocumentsPrinting',
    meta: {
      title: 'Documentos de impresión rápida',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: DocumentsPrintingView,
  },
]
