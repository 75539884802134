const ConsultingRoomPatients = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */ '@/modules/consultingroom/views/ConsultingRoomPatients'
  )
const ConsultingRoomAssistants = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */ '@/modules/consultingroom/views/ConsultingRoomAssistants'
  )

const ConsultingRoomSocialNetworks = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */
    '@/modules/consultingroom/views/MyConsultingRoom/ConsultingRoomSocialNetworks'
  )

const ConsultingRoomGeneralSettings = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */
    '@/modules/consultingroom/views/MyConsultingRoom/ConsultingRoomGeneralSettings'
  )

const ConsultingRoomReports = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */
    '@/modules/consultingroom/views/MyConsultingRoom/ConsultingRoomReports'
  )

const consultingRoomInfographics = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */
    '@/modules/consultingroom/views/ConsultingRoomInfographics'
  )

const consultingRoom = () =>
  import(
    /* webpackChunkName: "ConsultingRoom" */
    '@/modules/consultingroom/views/ConsultingRoom'
  )

export default [
  {
    path: '/consultorio/asistentes',
    name: 'ConsultingRoomAssistants',
    meta: {
      title: 'Administración de asistentes',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: ConsultingRoomAssistants,
  },
  {
    path: '/consultorio/pacientes',
    name: 'ConsultingRoomPatients',
    meta: {
      title: 'Administración de pacientes',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: ConsultingRoomPatients,
  },
  {
    path: '/consultorio/configuracion',
    name: 'consultingRoomGeneralSettings',
    meta: {
      title: 'Configuración del consultorio',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: ConsultingRoomGeneralSettings,
  },
  {
    path: '/consultorio/configuracion/redes',
    name: 'consultingRoomSocialNetworks',
    meta: {
      title: 'Administración de redes sociales',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: ConsultingRoomSocialNetworks,
  },
  {
    path: '/consultorio/configuracion/reportes',
    name: 'consultingRoomReports',
    meta: {
      title: 'Configuración de reportes',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: ConsultingRoomReports,
  },
  {
    path: '/consultorio/infografias',
    name: 'consultingRoomInfographics',
    meta: {
      title: 'Infografias',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: consultingRoomInfographics,
  },
  {
    path: '/consultorio',
    name: 'consultingRoom',
    meta: {
      title: 'Nuevo consultorio',
      requiresAuth: true,
      requiresConsultingRoomAdmin: true,
    },
    component: consultingRoom,
  },
]
