const _ = require('lodash')

/**
 * Formate el objeto de la cita que se enviara al servidor.
 */
export function finalUpdateAppointment(store) {
  let finalUpdateAppointment = _.cloneDeep(store.updateAppointment)
  return finalUpdateAppointment
}
/**
 * Formatea el objeto de la cita agendada que se enviara al servidor.
 */
export function finalUpdateScheduledAppointment(store) {
  let finalUpdateScheduledAppointment = _.cloneDeep(
    store.updateScheduledAppointment
  )
  return finalUpdateScheduledAppointment
}
