const PrescriptionConfiguration = () =>
  import('@/modules/prescriptions/views/PrescriptionConfiguration')

export default [
  {
    path: '/prescripcion/configuracion',
    name: 'PrescriptionConfiguration',
    meta: {
      title: 'Configuración de Prescripción',
      requiresAuth: true,
      requiresConsultingRoomAdmin: false,
    },
    component: PrescriptionConfiguration,
  },
]
