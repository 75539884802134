import axios from 'axios'

export async function getPrescriptionValid() {
  return new Promise((resolve, reject) => {
    let url = `/prescription/validate/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export async function getPrescriptionConfiguration() {
  return new Promise((resolve, reject) => {
    let url = `/prescription-configuration/`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export async function updatePrescriptionConfiguration(
  // eslint-disable-next-line no-unused-vars
  { commit },
  { formData, id }
) {
  return new Promise((resolve, reject) => {
    let url = `/prescription-configuration/${id}/`
    axios
      .patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
