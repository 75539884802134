const _ = require('lodash')

/**
 * Formate el objeto de la cita que se enviara al servidor.
 */
export function finalUpdateGeneralAppointment(store) {
  let finalUpdateGeneralAppointment = _.cloneDeep(
    store.updateAppointmentGeneral
  )
  return finalUpdateGeneralAppointment
}
